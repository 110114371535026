import React, { useState, useEffect, useRef } from 'react'; // Import React and necessary hooks
import PropTypes from 'prop-types'; // Import PropTypes for type checking
import { Helmet } from 'react-helmet'; // Import Helmet for managing document head
import './PageBannerPDFInsights.scss'; // Import the CSS file for styling
import { mediaApi } from '@sitecore-jss/sitecore-jss';
import { DATE_FORMATS } from '../../../utils/enums';
import moment from 'moment';
import Download from '../globals/svgicons/Download';
import FullScreen from '../globals/svgicons/FullScreen';
import LeftArrowChervon from '../globals/svgicons/LeftArrowChervon';
import RightArrowChervon from '../globals/svgicons/RightArrowChervon';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PageBannerPDFInsights = ({ fields }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [customWidth, setCustomWidth] = useState(0);
  const [scale, setScale] = useState(1);

  const { data } = fields;

  const nullishDate = '0001-01-01T00:00:00Z';
  const isValidDate = nullishDate === data?.publicationDate?.value;
  const dateFormat = DATE_FORMATS.DEFAULT_NEW_US_SMALL;

  const fullscreenRef = useRef(null);
  const pdfUrl = fields?.data?.file;
  // const pdfUrl = 'https://css4.pub/2015/textbook/somatosensory.pdf'; // URL of the PDF to be loaded
  // const pdfUrl = 'https://www.manpowergroup.com/-/media/project/manpowergroup/mpg-marketing/pdf/sustainability/2024/mpg-2024-esg-report-new.pdf';
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const onItemClick = ({ pageNumber }) => {
    setPageNumber(pageNumber);
  };

  const goToNextPage = () => setPageNumber((prev) => Math.min(prev + 1, numPages));
  const goToPrevPage = () => setPageNumber((prev) => Math.max(prev - 1, 1));

  const downloadPDF = () => {
    const link = document.createElement('a'); // Create a link element
    link.href = pdfUrl; // Set the href to the PDF URL
    link.download = `${data?.title}.pdf`; // Set the download attribute
    link.click(); // Trigger a click on the link to download the PDF
  };

  const toggleFullscreen = () => {
    if (typeof window === 'undefined') return; // Ensure window is defined

    const element = fullscreenRef.current;
    if (!isFullscreen) {
      const requestFullscreen =
        element.requestFullscreen ||
        element.mozRequestFullScreen ||
        element.webkitRequestFullscreen ||
        element.msRequestFullscreen;
      if (requestFullscreen) {
        requestFullscreen.call(element);
      }
    } else {
      const exitFullscreen =
        document.exitFullscreen ||
        document.mozCancelFullScreen ||
        document.webkitExitFullscreen ||
        document.msExitFullscreen;
      if (exitFullscreen) {
        exitFullscreen.call(document);
      }
    }
    element.onfullscreenchange = () => {
      if (!document.fullscreenElement) {
        setIsFullscreen(false);
      }
    };
    setIsFullscreen(!isFullscreen);
  };

  const onRenderAnnotationLayerSuccess = () => {
    const links = document.querySelectorAll('.pdf-document a');
    links.forEach((link) => {
      const url = new URL(link?.href);
      if (url?.origin !== window?.location?.origin) {
        link.setAttribute('target', '_blank');
      }
    });
  };

  const handleKeyDown = (event) => {
    if (!isFullscreen) return;

    switch (event.key) {
      case 'ArrowRight': // Go to next page
      case 'ArrowDown':
        goToNextPage();
        break;

      case 'ArrowLeft': // Go to previous page
      case 'ArrowUp':
        goToPrevPage();
        break;

      case 'Escape': // Exit fullscreen
        toggleFullscreen();
        break;

      default:
        break;
    }
  };

  // Function to calculate scale dynamically based on screen size
  const calculateScale = () => {
    if (!isFullscreen) return;

    const containerHeight = window.innerHeight;
    const pdfHeight = fullscreenRef.current.scrollHeight;

    // Only adjust scale if PDF height is larger than the screen height
    if (pdfHeight > containerHeight) {
      const newScale = containerHeight / pdfHeight;
      setScale(newScale);
    }
  };

  const handleScroll = (event) => {
    if (!isFullscreen) return;

    if (event.deltaY > 0) {
      goToNextPage();
    } else if (event.deltaY < 0) {
      goToPrevPage();
    }
  };

  useEffect(() => {
    if (isFullscreen) {
      setCustomWidth(window.innerWidth - 40);
      document.addEventListener('keydown', handleKeyDown);
      document.addEventListener('wheel', handleScroll);
      window.addEventListener('resize', calculateScale);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('resize', calculateScale);
      document.removeEventListener('wheel', handleScroll);
      setScale(1);
      setCustomWidth(
        fullscreenRef.current && fullscreenRef.current.clientWidth - 40
      );
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('resize', calculateScale);
      document.removeEventListener('wheel', handleScroll);
    };
  }, [isFullscreen, numPages]);

  return (
    <div className="container-xl page-banner-pdf-insights">
      <div className="page-banner-content">
        <h1 className="page-banner-title-insights">{data?.title}</h1>
        <p className="page-banner-topics-insights">
          <img
            src={data?.topic?.topicsIconDesktop}
            alt="icon"
            className="topics-icon"
          />
          <span> {data?.topic?.topicsTitle} </span>
          <span>
            {' '}
            &#8226;{' '}
            {!isValidDate &&
              `${moment(data?.publicationDate).format(dateFormat)}`}{' '}
            {/* &#8226; */}
          </span>
        </p>
      </div>
      <div className="pdf-viewer-container" id="fullscreenRef" ref={fullscreenRef}>
        <Helmet>
          <script type="module">
            {`
              var url = '${mediaApi.updateImageUrl(pdfUrl)}';
              var { pdfjsLib } = window;
              pdfjsLib.GlobalWorkerOptions.workerSrc = '//mozilla.github.io/pdf.js/build/pdf.worker.mjs';
            `}
          </script>
        </Helmet>
        <div className="pdf-container">
          <Document
            file={pdfUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={console.error}
            onItemClick={onItemClick}
            className="pdf-document"
            style={{ position: 'relative' }}
          >
            <Page
              pageNumber={pageNumber}
              // Enables clickable links & buttons
              renderTextLayer
              onItemClick={onItemClick}
              onRenderAnnotationLayerSuccess={onRenderAnnotationLayerSuccess}
              width={customWidth}
              scale={scale}
              // height={fullscreenRef.current ? (isFullscreen ? '83vh' : "" ) : ""}
            />
          </Document>
        </div>
        {!isFullscreen && (
          <div className="controls">
            <div className="left-controls">
              <button
                id="prev"
                className="control-button"
                onClick={goToPrevPage}
                disabled={pageNumber <= 1}
              >
                <LeftArrowChervon />
              </button>{' '}
              {/* Button for previous page */}
              <span className="page-info">
                Page: <span id="page_num">{pageNumber}</span> /{' '}
                <span id="page_count">{numPages}</span>
              </span>{' '}
              {/* Page info display */}
              <button
                id="next"
                className="control-button"
                onClick={goToNextPage}
                disabled={pageNumber >= numPages}
              >
                <RightArrowChervon />
              </button>{' '}
              {/* Button for next page */}
              <button
                id="home"
                className="control-button home-pdf-button"
                onClick={() => setPageNumber(1)}
              >
                Home
              </button>{' '}
              {/* Button for home page */}
            </div>
            <div className="right-controls">
              <button
                onClick={downloadPDF}
                className="control-button download-button"
              >
                <Download />
                <span className="right-controls-text"> Download PDF </span>
              </button>{' '}
              {/* Button for downloading the PDF */}
              <button
                onClick={toggleFullscreen}
                className="control-button fullscreen-button"
              >
                <FullScreen />
                <span className="right-controls-text">
                  {' '}
                  {isFullscreen ? 'Exit Full Screen' : 'Full Screen'}{' '}
                </span>
              </button>{' '}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

PageBannerPDFInsights.defaultProps = {
  fields: {
    data: {
      title: '',
      publicationDate: '',
      topic: {
        topicsTitle: '',
        topicsIconDesktop: '',
        topicsIconDesktopAlt: '',
        topicsIconMobile: null,
        topicsIconMobileAlt: null,
      },
      file: '',
      fileId: '',
    },
  },
};

PageBannerPDFInsights.propTypes = {
  fields: PropTypes.shape({
    data: PropTypes.shape({
      title: PropTypes.string,
      publicationDate: PropTypes.string,
      topic: PropTypes.shape({
        topicsTitle: PropTypes.string,
        topicsIconDesktop: PropTypes.string,
        topicsIconDesktopAlt: PropTypes.string,
        topicsIconMobile: PropTypes.string,
        topicsIconMobileAlt: PropTypes.string,
      }),
      file: PropTypes.string,
      fileId: PropTypes.string,
    }),
  }),
};

export default PageBannerPDFInsights;
