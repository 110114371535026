/* eslint-disable */
import { GTM } from '../../../src/constants';

const enums = {
  AUTHENTICATED: 'Authenticated',
  UNAUTHENTICATED: 'Non-authenticated',
};

const hasProfile = (responseData, loginState) => {
  return (
    !responseData?.hasResume &&
    !responseData?.hasExperience &&
    !responseData?.hasEducation &&
    loginState === 'AUTHENTICATED'
  );
};

const getExpOrEdu = (responseData, t) => {
  return `${responseData?.hasExperience ? t('Experience') : t('No Experience')} | ${
    responseData?.hasEducation ? t('Education') : t('No Education')
  }`;
};

export const jobApplyEvent = (
  applicationId,
  revenuePerApplication,
  data = {},
  loginState,
  responseData = {},
  t
) => {
  if (window?.dataLayer) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: GTM.EVENTS.JOB_APPLICATION_SUCCESS,
      ecommerce: {
        purchase: {
          actionField: {
            id: applicationId,
            revenue: revenuePerApplication || '',
          },
          products: [
            {
              name: data?.jobTitle,
              id: data?.jobId,
              price: revenuePerApplication || '',
              brand: data?.addtionalForDataLayer?.companyName
                ? data?.addtionalForDataLayer?.companyName
                : data?.companyName
                ? data?.companyName
                : '',
              category: data?.domain,
              variant: data?.employmentType,
              quantity: 1,
              dimension11: `Location: ${data?.jobLocation} | Education: ${
                data?.addtionalForDataLayer?.education
                  ? data?.addtionalForDataLayer?.education.join(', ')
                  : data?.education
                  ? data?.education.join(', ')
                  : undefined
              } | Salary: ${
                data?.salaryRate ? data?.salaryRate : data?.salaryUnit
              } | Hours: ${data?.JobType}`,

              dimension12: `${enums?.[loginState || 'UNAUTHENTICATED']}`,
              dimension13: data?.addtionalForDataLayer?.applicationType
                ? data?.addtionalForDataLayer?.applicationType
                : data?.applicationType
                ? data?.applicationType
                : undefined,
              dimension14: hasProfile(responseData, loginState)
                ? t('Existing Profile')
                : responseData?.hasResume || responseData?.hasAttachments
                ? t('With resume')
                : t('Without resume'),
              dimension15: hasProfile(responseData, loginState)
                ? t('Existing Profile')
                : getExpOrEdu(responseData, t),
              dimension16: data?.clientID ? data.clientID : undefined,
              dimension17: data?.jobLocation ? data?.jobLocation : undefined,
              dimension18: data?.addtionalForDataLayer?.education
                ? data?.addtionalForDataLayer?.education.join(', ')
                : data?.education
                ? data?.education.join(', ')
                : undefined,
              dimension19: data?.salaryRate ? data?.salaryRate : undefined,
              dimension20: data?.JobType ? data?.JobType : undefined,
            },
          ],
        },
      },
    });
  }
};

export const jobResultsEvent = (jobs = [], revenue, isCompanySearch, loginState) => {
  if (window?.dataLayer) {
    const impressions = [];
    jobs?.forEach((job, index) => {
      impressions.push({
        name: job.jobTitle,
        id: job.jobID || job.positionID,
        price: revenue || '',
        brand: job?.addtionalForDataLayer?.companyName
          ? job?.addtionalForDataLayer?.companyName
          : job?.companyName
          ? job?.companyName
          : '',
        category: job.domain,
        variant: job.employmentType,
        list: isCompanySearch ? 'Vacancies by employer' : 'Search Results',
        position: index + 1,
        dimension11: `Location: ${job?.jobLocation} | Education: ${
          job?.addtionalForDataLayer?.education
            ? job?.addtionalForDataLayer?.education.join(', ')
            : job?.education
            ? job?.education.join(', ')
            : undefined
        } | Salary: ${job?.salaryRate} | Hours: ${job?.jobType}`,
        dimension12: `${enums?.[loginState || 'UNAUTHENTICATED']}`,
        dimension13: job?.addtionalForDataLayer?.applicationType
          ? job?.addtionalForDataLayer?.applicationType
          : job?.applicationType
          ? job?.applicationType
          : undefined,
        dimension14: undefined,
        dimension15: undefined,
        dimension16: job?.clientID ? job?.clientID : undefined,
        dimension17: job?.jobLocation ? job?.jobLocation : undefined,
        dimension18: job?.addtionalForDataLayer?.education
          ? job?.addtionalForDataLayer?.education.join(', ')
          : job?.education
          ? job?.education.join(', ')
          : undefined,
        dimension19: job?.salaryRate ? job?.salaryRate : undefined,
        dimension20: job?.jobType ? job?.jobType : undefined,
      });
    });

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: isCompanySearch
        ? GTM.EVENTS.SEO_JOB_SEARCH_RESULTS
        : GTM.EVENTS.JOB_SEARCH_RESULTS,
      ecommerce: {
        impressions: impressions,
      },
    });
  }
};

export const seojobResultsEvent = (jobs = [], revenue, loginState, jobCategory) => {
  if (window?.dataLayer) {
    const impressions = [];
    jobs?.forEach((job, index) => {
      impressions.push({
        name: job.jobTitle,
        id: job.jobID || job.positionID,
        price: revenue || '',
        brand: job?.addtionalForDataLayer?.companyName
          ? job?.addtionalForDataLayer?.companyName
          : job?.companyName
          ? job?.companyName
          : '',
        category: job.domain,
        variant: job.employmentType,
        list: `${jobCategory ? `Vacancies by ${jobCategory}` : 'Vacancies'}`,
        position: index + 1,
        dimension11: `Location: ${job?.jobLocation} | Education: ${
          job?.addtionalForDataLayer?.education
            ? job?.addtionalForDataLayer?.education.join(', ')
            : job?.education
            ? job?.education.join(', ')
            : undefined
        } | Salary: ${job?.salaryRate} | Hours: ${job?.jobType}`,
        dimension12: `${enums?.[loginState || 'UNAUTHENTICATED']}`,
        dimension13: job?.addtionalForDataLayer?.applicationType
          ? job?.addtionalForDataLayer?.applicationType
          : job?.applicationType
          ? job?.applicationType
          : undefined,
        dimension14: undefined,
        dimension15: undefined,
        dimension16: job?.clientID ? job?.clientID : undefined,
        dimension17: job?.jobLocation ? job?.jobLocation : undefined,
        dimension18: job?.addtionalForDataLayer?.education
          ? job?.addtionalForDataLayer?.education.join(', ')
          : job?.education
          ? job?.education.join(', ')
          : undefined,
        dimension19: job?.salaryRate ? job?.salaryRate : undefined,
        dimension20: job?.jobType ? job?.jobType : undefined,
      });
    });

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: GTM.EVENTS.SEO_JOB_SEARCH_RESULTS,
      ecommerce: {
        impressions: impressions,
      },
    });
  }
};

export const jobVacancyEvent = (eventName, job, revenue, loginState) => {
  if (window?.dataLayer) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: eventName,
      ecommerce: {
        detail: {
          products: [
            {
              name: job?.jobTitle,
              id: job?.jobID || job?.positionID,
              price: revenue || '',
              brand: job?.addtionalForDataLayer?.companyName
                ? job?.addtionalForDataLayer?.companyName
                : job?.companyName
                ? job?.companyName
                : '',
              category: job?.domain,
              variant: job?.employmentType,
              quantity: 1,
              dimension11: `Location: ${job?.jobLocation} | Education: ${
                job?.addtionalForDataLayer?.education
                  ? job?.addtionalForDataLayer?.education.join(', ')
                  : job?.education
                  ? job?.education.join(', ')
                  : undefined
              } | Salary: ${job?.salaryRate} | Hours: ${job?.jobType}`,
              dimension12: `${enums?.[loginState || 'UNAUTHENTICATED']}`,
              dimension13: job?.addtionalForDataLayer?.applicationType
                ? job?.addtionalForDataLayer?.applicationType
                : job?.applicationType
                ? job?.applicationType
                : undefined,
              dimension14: undefined,
              dimension15: undefined,
              dimension16: job?.clientID ? job?.clientID : undefined,
              dimension17: job?.jobLocation ? job?.jobLocation : undefined,
              dimension18: job?.addtionalForDataLayer?.education
                ? job?.addtionalForDataLayer?.education.join(', ')
                : job?.education
                ? job?.education.join(', ')
                : undefined,
              dimension19: job?.salaryRate ? job?.salaryRate : undefined,
              dimension20: job?.jobType ? job?.jobType : undefined,
            },
          ],
        },
      },
    });
  }
};

export const searchResultClickEvent = (
  eventName,
  job,
  revenue,
  loginState,
  jobCategory,
  isCompanySearch,
  index
) => {
  if (window?.dataLayer) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: eventName,
      ecommerce: {
        click: {
          actionField: {
            list: `${
              jobCategory
                ? `Vacancies by ${jobCategory}`
                : `${isCompanySearch ? `Vacancies by employer` : `Search Results`}`
            }`,
          },
          products: [
            {
              name: job?.jobTitle,
              id: job?.jobID || job?.positionID,
              price: revenue || '',
              brand: job?.addtionalForDataLayer?.companyName
                ? job?.addtionalForDataLayer?.companyName
                : job?.companyName
                ? job?.companyName
                : '',
              category: job?.domain,
              variant: job?.employmentType,
              quantity: 1,
              position: index + 1,
              dimension11: `Location: ${job?.jobLocation} | Education: ${
                job?.addtionalForDataLayer?.education
                  ? job?.addtionalForDataLayer?.education.join(', ')
                  : job?.education
                  ? job?.education.join(', ')
                  : undefined
              } | Salary: ${job?.salaryRate} | Hours: ${job?.jobType}`,
              dimension12: `${enums?.[loginState || 'UNAUTHENTICATED']}`,
              dimension13: job?.addtionalForDataLayer?.applicationType
                ? job?.addtionalForDataLayer?.applicationType
                : job?.applicationType
                ? job?.applicationType
                : undefined,
              dimension14: undefined,
              dimension15: undefined,
              dimension16: job?.clientID ? job?.clientID : undefined,
              dimension17: job?.jobLocation ? job?.jobLocation : undefined,
              dimension18: job?.addtionalForDataLayer?.education
                ? job?.addtionalForDataLayer?.education.join(', ')
                : job?.education
                ? job?.education.join(', ')
                : undefined,
              dimension19: job?.salaryRate ? job?.salaryRate : undefined,
              dimension20: job?.jobType ? job?.jobType : undefined,
            },
          ],
        },
      },
    });
  }
};

export const preJobApplyEvent = (jobStorageDetails, revenue, loginState) => {
  if (window?.dataLayer) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: GTM.EVENTS.JOB_APPLICATION,
      ecommerce: {
        checkout: {
          actionField: { step: 1 },
          products: [
            {
              name: jobStorageDetails?.jobTitle,
              id: jobStorageDetails?.jobId,
              price: revenue || '',
              brand: jobStorageDetails?.addtionalForDataLayer?.companyName
                ? jobStorageDetails?.addtionalForDataLayer?.companyName
                : jobStorageDetails?.companyName
                ? jobStorageDetails?.companyName
                : '',
              category: jobStorageDetails?.domain,
              variant: jobStorageDetails?.employmentType,
              quantity: 1,
              dimension11: `Location: ${
                jobStorageDetails?.jobLocation
              } | Education: ${
                jobStorageDetails?.addtionalForDataLayer?.education
                  ? jobStorageDetails?.addtionalForDataLayer?.education.join(', ')
                  : jobStorageDetails?.education
                  ? jobStorageDetails?.education.join(', ')
                  : undefined
              } | Salary: ${
                jobStorageDetails?.salaryRate
                  ? jobStorageDetails?.salaryRate
                  : jobStorageDetails?.salaryUnit
              } | Hours: ${jobStorageDetails?.JobType}`,
              dimension12: `${enums?.[loginState || 'UNAUTHENTICATED']}`,
              dimension13: jobStorageDetails?.addtionalForDataLayer?.applicationType
                ? jobStorageDetails?.addtionalForDataLayer?.applicationType
                : jobStorageDetails?.applicationType
                ? jobStorageDetails?.applicationType
                : undefined,
              dimension14: undefined,
              dimension15: undefined,
              dimension16: jobStorageDetails?.clientID
                ? jobStorageDetails?.clientID
                : undefined,
              dimension17: jobStorageDetails?.jobLocation
                ? jobStorageDetails?.jobLocation
                : undefined,
              dimension18: jobStorageDetails?.addtionalForDataLayer?.education
                ? jobStorageDetails?.addtionalForDataLayer?.education.join(', ')
                : jobStorageDetails?.education
                ? jobStorageDetails?.education.join(', ')
                : undefined,
              dimension19: jobStorageDetails?.salaryRate
                ? jobStorageDetails?.salaryRate
                : undefined,
              dimension20: jobStorageDetails?.JobType
                ? jobStorageDetails?.JobType
                : undefined,
            },
          ],
        },
      },
    });
  }
};
