import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import CardInsights from '../../Cards/CardInsights';
import ComponentWrapper from '../../ComponentWrapper';
import { getComponentProps } from '../../../../utils/helperUtils';
import { useReadParams as readParams } from '../../../../hooks/useReadParams';

import '../cardscroll.scss';

/**
 * @description ConnectorCardScroll
 * @param {*} props - props
 * @returns {node} html
 */
const ConnectorCardScroll = (props) => {
  const {
    fields,
    params,
    rendering: { uid },
  } = props;
  const updatedParams = (params && readParams(params)) || {};
  const gradientObj = updatedParams['contentPresentationStyle'] || {};
  const arrowButtonPrevRef = useRef();
  const arrowButtonNextRef = useRef();

  const ArrowButton = (props) => {
    const {
      className,
      style,
      onClick,
      currentSlide,
      slideCount,
      prevBtn,
      nextBtn,
    } = props;

    const [showAriaLabel, setShowAriaLabel] = useState(false);

    const setAttribute = (ref) => ref?.current?.setAttribute('aria-hidden', true);
    const removeAttribute = (ref) =>
      ref?.current?.setAttribute('aria-hidden', false);

    const handleArrowClick = () => {
      if (nextBtn || !prevBtn || currentSlide !== 0) {
        if (!(nextBtn && currentSlide === slideCount - 1)) {
          setShowAriaLabel(true);
          setAttribute(prevBtn ? arrowButtonPrevRef : arrowButtonNextRef);
          removeAttribute(nextBtn ? arrowButtonNextRef : arrowButtonPrevRef);
          onClick && onClick();
        }
      }
    };

    const handleAriaLabel = () => {
      if (showAriaLabel) return `slide ${currentSlide + 1} of ${slideCount}`;
      else if (prevBtn) return 'previous';
      return 'next';
    };

    return (
      <button
        ref={prevBtn ? arrowButtonPrevRef : arrowButtonNextRef}
        className={className}
        style={{ ...style }}
        onClick={handleArrowClick}
        aria-label={handleAriaLabel()}
        onBlur={() => setShowAriaLabel(false)}
      />
    );
  };

  const slideLoadHook = () => {
    if (typeof window !== 'undefined') {
      document.querySelectorAll(`.scroll_${uid} .slick-slide`).forEach((node) => {
        if (node.className.includes('slick-active')) {
          node.style.visibility = '';
          node.setAttribute('aria-hidden', 'false');
        } else {
          node.style.visibility = 'hidden';
          node.setAttribute('aria-hidden', 'true');
        }
      });
    }
  };

  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    lazyLoad: true,
    accessibility: true,
    arrows: true,
    onLazyLoad: () => slideLoadHook(),
    afterChange: () => slideLoadHook(),
    // eslint-disable-next-line react/display-name
    appendDots: (dots) => {
      return (
        <ul style={{ margin: '0px' }} className={'slick-dots'}>
          {dots.map((dot, index) => (
            <li key={index} className={dot.props.className}>
              <button
                onClick={dot.props.children.props.onClick}
                aria-label={`Slide ${dot.props.children.props.children}`}
                aria-current={dot.props.className ? 'true' : 'false'}
              >
                {dot.props.children.props.children}
              </button>
            </li>
          ))}
        </ul>
      );
    },
    responsive: [
      {
        breakpoint: 992,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 564,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
    nextArrow: <ArrowButton nextBtn prevBtn={false} />,
    prevArrow: <ArrowButton prevBtn nextBtn={false} />,
  };

  const propVal = getComponentProps(updatedParams, [
    'card-scroll',
    'connector',
    'connector-card-scroll',
    `scroll_${uid}`,
  ]);

  return (
    <ComponentWrapper {...propVal}>
      <div className="container">
        <div className="row">
          <div className="col-xl-10 offset-xl-1 offset-lg-0 col-lg-12">
            <div className="card-scroll-header">
              <h2 className="title">{fields?.heading?.value}</h2>
            </div>
            <Slider {...settings}>
              {fields?.items?.map((item) => (
                <CardInsights
                  key={item?.fields?.heading}
                  title={item?.fields?.heading}
                  img={item?.fields?.thumbnail}
                  text={item?.fields?.paragraphText}
                  ctaText={item?.fields?.ctaTextOverride}
                  ctaUrl={item?.fields?.ctaUrl}
                  gradient={gradientObj?.gradient}
                />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </ComponentWrapper>
  );
};

ConnectorCardScroll.defaultProps = {
  fields: {
    title: '',
    items: [],
  },
  rendering: {},
  params: {},
};

ConnectorCardScroll.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.shape({
      value: PropTypes.string,
    }),
    ctaText: PropTypes.shape({
      value: PropTypes.string,
    }),
    ctaUrl: PropTypes.shape({
      value: PropTypes.shape({}),
    }),
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  rendering: PropTypes.shape({ uid: PropTypes.string }).isRequired,
  params: PropTypes.shape({}).isRequired,
};

export default ConnectorCardScroll;
