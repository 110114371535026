import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import './ContentBlock.scss';

const ContentBlock = ({ fields }) => {
  const content = fields?.content;
  const [isExpanded, setIsExpanded] = useState(false);
  const [isMobile, setIsMobile] = useState(
    typeof window !== 'undefined' ? window.innerWidth <= 575 : false
  );

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 575);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="content-wrapper content-insights-block">
      <div className="container-xl">
        <div className={`content-insights ${isExpanded ? 'expanded' : 'clamped'}`}>
          <RichText field={content} />
        </div>
        {isMobile && !isExpanded && (
          <div className="read-more-container d-md-none d-lg-none d-xl-none">
            <button className="read-more-btn" onClick={() => setIsExpanded(true)}>
              Read More
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

ContentBlock.defaultProps = {
  fields: {
    content: { value: '' },
  },
};

ContentBlock.propTypes = {
  fields: PropTypes.shape({
    content: PropTypes.shape({ value: PropTypes.string.isRequired }),
  }),
};

export default ContentBlock;
