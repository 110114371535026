import React, { useState, useEffect } from 'react';
import { separator } from '../../../utils/enums';
import { originRegex, joinStrings } from '../../../utils/helperUtils';
import './countrylinks.scss';

const CountryLinks = (props) => {
  const [countryChunks, setCountryChunks] = useState([]);
  const { region } = props;
  const chunk_size = Math.ceil(region?.countries?.length / 3);
  const [origin, setOrigin] = useState();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const hostname = window.location.hostname;
      let index = 0;
      const arrayLength = region?.countries?.length;
      const tempArray = [];

      for (index = 0; index < arrayLength; index += chunk_size) {
        const myChunk = region?.countries?.slice(index, index + chunk_size);
        // Do something if you want with the group
        //countryChunks[index] = [];
        tempArray.push(myChunk);
      }

      setCountryChunks(tempArray);
      setOrigin(hostname?.replace('www.', ''));
    }
  }, []);

  return (
    <div className="row">
      {countryChunks.map((chunk, index) => {
        if (chunk.length === 0) return;
        return (
          <div className="col-md-4 country-list" key={index}>
            <ul className="countries">
              {Array.isArray(chunk) &&
                chunk.map((country, index) => {
                  return (
                    <li key={index}>
                      <span>{country?.countryName}</span>
                      {country &&
                        Array.isArray(country.languages) &&
                        country.languages.map((language) => {
                          const redirectURL = language?.redirectURL;
                          const hasOrigin =
                            redirectURL?.includes(origin) &&
                            originRegex(origin)?.test(redirectURL);
                          const titleStr = joinStrings(
                            [region.regionName, country?.countryName],
                            separator.HYPHENSPACE
                          );
                          return (
                            <>
                              {'|'}
                              <a
                                href={redirectURL}
                                target={`${hasOrigin ? '' : '_blank'}`}
                                title={
                                  hasOrigin
                                    ? titleStr
                                    : `${titleStr}, opens in a new tab/ window`
                                }
                              >
                                {language?.name}
                              </a>
                            </>
                          );
                        })}
                    </li>
                  );
                })}
            </ul>
          </div>
        );
      })}
    </div>
  );
};

export default CountryLinks;
