import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CardImage from '../Cards/CardImage';
import { getComponentId } from '../../../utils/helperUtils';
import { useReadParams as readParams } from '../../../hooks/useReadParams';
import './togglecardblocks.scss';

/**
 * @description - CardBlockToggle component.
 * @param {object} props - Input props
 * @returns {Node} - HTML node.
 */
const CardBlockToggle = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { fields, params } = props;
  const { paragraphText, items } = fields;
  const [currentActiveTabIndex, setCurrentActiveTabIndex] = useState(0); // this tracks currently viewed tab in nav
  const updatedParams = (params && readParams(params)) || {};
  const gradientObj = updatedParams['contentPresentationStyle'] || {};
  const ctaColor = updatedParams['ctaButtonColor'] || {};
  const componentId = getComponentId(updatedParams);

  /**
   * @description - switches the nav tabs.
   * @param {number} selectedIndex - Nav index which need to be activated
   * @returns {void} - void.
   */
  const tabItemClicked = (selectedIndex) => {
    setCurrentActiveTabIndex(selectedIndex);
  };

  return (
    <section
      id={componentId}
      className={`card-block tabbed__block ${ctaColor?.buttonColor}`}
    >
      <div className="container">
        <nav>
          <div className="nav card__tabs" id="jobs" role="tablist">
            {items?.map((item, navTabIndex) => (
              <button
                style={{ background: 'transparent', border: 0 }}
                key={'tabbedNavItemNo_' + navTabIndex}
                className={`card__item card__link ${
                  currentActiveTabIndex === navTabIndex ? 'active' : ''
                }`}
                id={'card-tab' + navTabIndex}
                data-toggle="tab"
                role="tab"
                aria-controls={'cardTabGroupNo_' + navTabIndex}
                aria-selected={currentActiveTabIndex === navTabIndex ? true : false}
                onClick={() => tabItemClicked(navTabIndex)}
              >
                {item?.fields?.title?.value}
              </button>
            ))}
            <span className="slider"></span>
          </div>
        </nav>
        <p className="text-center">{paragraphText?.value}</p>
        <div className="tab__content" id="card-left">
          {items?.map((item, navTabIndex) => {
            return (
              <>
                <div
                  key={'tab__content_no_' + navTabIndex}
                  className={`tab__pane fade ${
                    currentActiveTabIndex === navTabIndex ? 'show active' : ''
                  }`}
                  id={'card-left'}
                  role="tabpanel"
                  aria-labelledby={'card-tab' + navTabIndex}
                >
                  <div className="row ">
                    {item?.fields?.items?.map((item, index) => (
                      <div
                        key={index}
                        className="col-lg-4 col-md-6 col-sm-12 d-flex margin-bottom-40"
                      >
                        <CardImage
                          title={item?.fields?.title?.value}
                          ctaUrl={item?.fields?.ctaUrl?.value}
                          ctaText={item?.fields?.ctaText?.value}
                          image={item?.fields?.image?.value}
                          text={item?.fields?.text?.value}
                          gradientObj={gradientObj?.gradient}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </section>
  );
};
CardBlockToggle.defaultProps = {
  fields: {},
  params: {},
};

CardBlockToggle.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.shape({
      value: PropTypes.string,
    }),
    paragraphText: PropTypes.shape({
      value: PropTypes.string,
    }),
    ctaText: PropTypes.shape({
      value: PropTypes.string,
    }),
    ctaUrl: PropTypes.shape({
      value: PropTypes.shape({}),
    }),
    items: PropTypes.shape([]),
  }),
  params: PropTypes.shape({}),
};
export default CardBlockToggle;
